import { router } from '@/router';
import VueVimeoPlayer from 'vue-vimeo-player';
import { store } from '@/store';
import VueModal from '@/plugins/modal';
import VueRemoveEdges from '@/plugins/edges';
import { createApolloProvider } from '@vue/apollo-option';
import apolloClients from './apollo';
import flavorPlugin from '@/plugins/flavor';
import VueMatomo from 'vue-matomo';
import * as Sentry from '@sentry/vue';

const { publicApolloClient, privateApolloClient } = apolloClients;

const apolloProvider = createApolloProvider({
  clients: {
    publicClient: publicApolloClient,
  },
  defaultClient: privateApolloClient,
});
const registerPlugins = (app: any) => {
  if (import.meta.env.SENTRY_DSN) {
    Sentry.init({
      app,
      dsn: import.meta.env.SENTRY_DSN,
      environment: import.meta.env.SENTRY_ENVIRONMENT || 'localhost',
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.vueIntegration({
          tracingOptions: {
            trackComponents: true,
          },
        }),
        Sentry.replayIntegration({
          stickySession: false,
          beforeErrorSampling: (event) => {
            // we do not need replays for these kinds of errors, as the reason for them is known and they don't need to
            // be reproduced
            const excludedPatterns = [
              'Unable to preload CSS for',
              'Response not successful: Received status code 402',
              'crypto.randomUUID', // old browsers don't have an implementation of the randomUUID function, but we don't support these
            ];
            const value = event.exception?.values?.[0]?.value;
            for (const pattern of excludedPatterns) {
              if (value?.includes(pattern)) {
                return false;
              }
            }
            return true;
          },
        }),
      ],
      tracesSampleRate: 1,
      replaysSessionSampleRate: 0.0,
      replaysOnErrorSampleRate: 0.25,
      ignoreErrors: [
        'Non-Error promise rejection captured', // probably caused by the Office 365 crawler: https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-865857552
      ],
    });
  }
  app.use(store);
  app.use(VueModal);
  app.use(VueRemoveEdges);
  app.use(VueVimeoPlayer);
  app.use(apolloProvider);
  app.use(router);
  app.use(flavorPlugin);
  if (import.meta.env.MATOMO_HOST) {
    // MS-628: we use VueMatomo "only" to setup the Matomo tracker
    // we will not use any of the provided functions
    app.use(VueMatomo, {
      host: import.meta.env.MATOMO_HOST,
      siteId: import.meta.env.MATOMO_SITE_ID,
      enableHeartBeatTimer: true,
      // we don't want the default vue-matomo router behaviour
      router: null,
    });
  }
};

export default registerPlugins;
