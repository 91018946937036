<template>
  <router-link
    :to="moduleLink"
    :class="['module-teaser', { 'module-teaser--small': !teaser }]"
  >
    <wagtail-image
      class="module-teaser__image"
      :src="heroImage.src"
      :srcset="heroImage.srcset"
      :original-height="heroImage.height"
      :original-width="heroImage.width"
      v-if="heroImage"
    ></wagtail-image>
    <div class="module-teaser__body">
      <div class="module-teaser__content">
        <h3 class="module-teaser__content-meta-title">{{ metaTitle }}</h3>
        <h3 class="module-teaser__content-title">
          {{ title }}
        </h3>
        <p class="module-teaser__content-description">
          {{ teaser }}
        </p>
      </div>

      <div
        class="module-teaser__pills"
        v-if="$flavor.showModuleFilter"
      >
        <pill :text="level?.name"></pill>
        <pill :text="category?.name"></pill>
      </div>
    </div>
  </router-link>
</template>

<script setup lang="ts">
import Pill from '@/components/ui/Pill.vue';
import { ModuleCategoryNode, ModuleLevelNode, WagtailImageNode } from '@/__generated__/graphql';
import { computed } from '@vue/reactivity';
import WagtailImage from '@/components/ui/WagtailImage.vue';

export interface Props {
  metaTitle: string;
  title: string;
  teaser: string;
  slug: string;
  heroImage: WagtailImageNode;
  level?: ModuleLevelNode;
  category?: ModuleCategoryNode;
  language?: string;
}

const props = defineProps<Props>();

const moduleLink = computed(() => {
  if (props.slug) {
    return {
      name: 'module',
      params: {
        slug: props.slug,
      },
    };
  } else {
    return {};
  }
});
</script>

<style scoped lang="scss">
@import 'styles/helpers';

@mixin two-line-text {
  //Max height of text 2 lines and truncate with ... when overflowing
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.module-teaser {
  box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.12);
  border: 1px solid #e2e2e2;
  min-height: 390px;
  width: 100%;
  border-radius: $default-border-radius;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
  max-width: $topic-mobile-width;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: flex-start;
  @include desktop {
    max-width: $topic-desktop-width;
  }

  &--small {
    min-height: 300px;
    height: 300px;
  }

  &__image {
    width: 100%;
    max-height: 150px;
    height: 150px;
    //prevent image from shrinking
    flex-shrink: 0;
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: $medium-spacing;
  }

  &__pills {
    margin-top: auto;
  }

  &__content {
    margin-bottom: $medium-spacing;
  }

  &__content-meta-title {
    color: $color-silver-dark;
    margin-bottom: $large-spacing;
    @include regular-text;
  }

  &__content-title {
    @include heading-3;
    margin-bottom: $small-spacing;
    @include two-line-text;
  }

  &__content-description {
    line-height: $default-line-height;
    font-size: 1.2rem;
    @include two-line-text;
  }
}
</style>
