import MODULE_DETAILS_QUERY from './gql/queries/modules/moduleDetailsQuery.gql';
import ME_QUERY from './gql/queries/meQuery.gql';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { graphql } from '@/__generated__/gql';
import { getCanManageContent, getCurrentClassName, getTopicRoute } from '@/mixins/me';

const defaultMe = {
  me: {
    selectedClass: {
      id: '',
      readOnly: false,
    },
    permissions: [],
    schoolClasses: [],
    isTeacher: false,
    team: null,
    readOnly: false,
    lastTopic: undefined,
    lastModuleLevel: undefined,
    recentModules: [],
  },
};

const _fragments = graphql(`
  fragment ChapterParts on ChapterNode {
    id
    title
    description
    bookmark {
      note {
        id
        text
      }
    }
    titleHiddenFor {
      id
      name
    }
    descriptionHiddenFor {
      id
      name
    }
    highlights {
      ...HighlightParts
    }
  }

  fragment ContentBlockParts on ContentBlockNode {
    id
    slug
    userCreated
    mine
    highlights {
      ...HighlightParts
    }
    instrumentCategory {
      id
      foreground
      background
      name
    }
    bookmarks {
      uuid
      note {
        id
        text
      }
    }
    hiddenFor {
      id
      name
    }
    visibleFor {
      id
      name
    }
  }

  fragment ContentBlockInterfaceParts on ContentBlockInterface {
    title
    type
    contents
    __typename
  }

  fragment ModuleContentParts on ModuleNode {
    chapters {
      ...ChapterParts
      contentBlocks {
        ...ContentBlockParts
        ...ContentBlockInterfaceParts
      }
    }
  }
`);

export const moduleQuery = graphql(`
  query ModuleDetailsQuery($slug: String, $id: ID) {
    module(slug: $slug, id: $id) {
      ...ModuleParts
      ...ModuleContentParts
      ... on NoLicenseResult {
        topic {
          slug
          title
        }
      }
    }
  }
`);

export const getModule = () => {
  const route = useRoute();
  const query = graphql(MODULE_DETAILS_QUERY, {
    slug: route.params.slug,
  });
  const { result } = useQuery(query);
  const module = computed(() => result.value?.module || {});

  return { module };
};

export function meQuery() {
  return {
    query: ME_QUERY,
  };
}

graphql(`
  fragment SchoolClassParts on SchoolClassNode {
    id
    name
  }
`);
graphql(`
  fragment UserParts on PrivateUserNode {
    id
    pk
    username
    email
    firstName
    lastName
    avatarUrl
    expiryDate
    readOnly
    lastModuleLevel {
      id
      name
      filterAttributeType
    }
    lastModule {
      id
      slug
    }
    lastTopic {
      id
      slug
    }
    selectedClass {
      id
      readOnly
    }
    recentModules {
      ...ModuleParts
    }
    schoolClasses {
      ...SchoolClassParts
    }
  }
`);
graphql(`
  fragment TeamParts on TeamNode {
    name
    code
    id
    members {
      firstName
      lastName
      id
      isMe
    }
  }
`);

graphql(`
  fragment WagtailImageParts on WagtailImageNode {
    id
    src
    alt
    width
    height
    title
    srcset
  }
`);

graphql(`
  fragment ModuleParts on ModuleNode {
    id
    title
    metaTitle
    teaser
    intro
    slug
    heroImage {
      ...WagtailImageParts
    }
    heroSource
    readOnly
    solutionsEnabled
    highlights {
      ...HighlightParts
    }
    language
    inEditMode @client
    level {
      id
      name
    }
    category {
      id
      name
    }
    topic {
      slug
      title
    }
    bookmark {
      note {
        id
        text
      }
    }
  }
`);

export const MeFragment = graphql(`
  fragment MeFragment on PrivateUserNode {
    ...UserParts
    team {
      ...TeamParts
    }
    isTeacher
    permissions
    onboardingVisited
  }
`);

export const useMe = () => {
  const query = graphql(`
    query MeQuery {
      me {
        ...MeFragment
      }
    }
  `);
  const { result } = useQuery(query);
  const me = computed(() => result.value?.me || defaultMe.me);
  const topicRoute = computed(() => {
    return getTopicRoute(me.value);
  });
  const schoolClass = computed(() => {
    return me.value.selectedClass;
  });
  const canManageContent = computed(() => {
    return getCanManageContent(me.value);
  });
  const currentClassName = computed(() => {
    return getCurrentClassName(me.value);
  });

  return { me, topicRoute, schoolClass, canManageContent, currentClassName };
};

export const newsQuery = graphql(`
  query NewsTeasers {
    newsTeasers {
      id
      description
      title
      imageUrl
      newsArticleUrl
      displayDate
      imageSource
    }
  }
`);

export const MySchoolClassFragment = graphql(`
  fragment MySchoolClassFragment on PrivateUserNode {
    id
    isTeacher
    readOnly
    selectedClass {
      id
      name
      code
      readOnly
      members {
        id
        firstName
        lastName
        isTeacher
        active
        isMe
      }
    }
  }
`);

export const InstrumentFragment = graphql(`
  fragment InstrumentParts on InstrumentNode {
    id
    title
    intro
    slug
    language
    bookmarks {
      uuid
      note {
        id
        text
      }
    }
    type {
      id
      name
      category {
        id
        name
        foreground
        background
      }
      type
    }
    contents
    highlights {
      ...HighlightParts
    }
  }
`);

export const mySchoolClassQuery = graphql(`
  query MySchoolClassQuery {
    me {
      ...MySchoolClassFragment
    }
  }
`);

export const allProjectsQuery = graphql(`
  query ProjectsQuery {
    projects {
      ...ProjectParts
    }
  }
`);
