<template>
  <div
    :class="[{ 'no-scroll': showModal || showMobileNavigation }, themeClass, 'app']"
    id="app"
  >
    <read-only-banner />
    <scroll-up />
    <component
      :is="showModalDeprecated"
      v-if="showModalDeprecated"
    />
    <component
      :is="showModal"
      v-if="showModal"
    />
    <component :is="layout" />
  </div>
</template>

<script setup lang="ts">
import ReadOnlyBanner from '@/components/ReadOnlyBanner.vue';
import ScrollUp from '@/components/ScrollUp.vue';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
/*
 *  the components used in the dynamic components are now defined globally with the `registerGlobalComponents` function
 */

const themeClass = `app--${import.meta.env.THEME}`;

const route = useRoute();

const layout = computed(() => {
  const layoutName = route.meta.layout || 'default';
  return `${layoutName}-layout`;
});
</script>

<script lang="ts">
import { mapGetters } from 'vuex';

export default {
  name: 'App',

  computed: {
    ...mapGetters({
      showModalDeprecated: 'showModal', // don't use this any more todo: remove this
      showMobileNavigation: 'showMobileNavigation',
    }),
    showModal() {
      return this.$modal.state.component;
    },
  },
};
</script>

<style lang="postcss">
body {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
}

.app {
  /*overflow-y: auto;*/
  min-height: 100vh;
  /*for IE10+*/
  display: flex;
  flex-direction: column;
}

.no-scroll {
  overflow-y: hidden;
}
</style>
