import { createStore } from 'vuex';

// WARNING fixme todo: please do not use this anymore, use the local GraphQL cache
// export default new Vuex.Store({
export const store = createStore({
  modules: {},

  state: {
    specialContainerClass: '',
    showModal: '',
    showMobileNavigation: false,
    contentBlockPosition: {},
    scrollPosition: 0,
    currentContent: '',
    currentNoteBlock: '',
    noteType: '',
    currentRoomEntry: '',
    parentRoom: null,
    parentModule: '',
    parentProject: null,
    currentNote: null,
    currentProjectEntry: null,
    currentNoteParent: '',
    imageUrl: '',
    infographic: {
      id: 0,
      type: '',
    },
    vimeoId: null,
    scrollingToAssignment: false,
    editModule: false,
    modulePayload: [],
    modalResolve: () => {},
    modalReject: () => {},
  },

  getters: {
    showModal: (state) => state.showModal,
    showMobileNavigation: (state) => state.showMobileNavigation,
    scrollingToAssignment: (state) => state.scrollingToAssignment,
    currentProjectEntry: (state) => state.currentProjectEntry,
    currentContent: (state) => state.currentContent,
    currentNoteBlock: (state) => state.currentNoteBlock,
    currentNote: (state) => state.currentNote,
    currentNoteParent: (state) => state.currentNoteParent,
    noteType: (state) => state.noteType,
    modulePayload: (state) => state.modulePayload,
  },

  // todo: clean up
  actions: {
    setSpecialContainerClass({ commit }, payload) {
      commit('setSpecialContainerClass', payload);
    },
    confirmModal({ dispatch, state }) {
      dispatch('hideModal');
      state.modalResolve();
    },
    cancelModal({ dispatch, state }) {
      dispatch('hideModal');
      state.modalReject();
    },
    hideModal({ commit, dispatch }) {
      document.body.classList.remove('no-scroll'); // won't get at the body any other way
      commit('setModal', false);
      dispatch('resetModalState');
    },
    resetModalState({ commit }) {
      commit('setCurrentRoomEntry', '');
      commit('setCurrentContent', '');
      commit('setCurrentNoteBlock', '');
      commit('setCurrentNoteParent', '');
      commit('setContentBlockPosition', {});
      commit('setParentRoom', null);
      commit('setParentModule', '');
      commit('setParentProject', null);
      commit('setCurrentProjectEntry', null);
      commit('setImageUrl', '');
      commit('setInfographic', {
        id: 0,
        type: '',
      });
      commit('setVimeoId', null);
      commit('setCurrentNote', null);
      commit('setNoteType', '');
      commit('setModulePayload', []);
    },
    resetContentBlockPosition({ commit }) {
      commit('setContentBlockPosition', {});
    },
    resetCurrentNoteBlock({ commit }) {
      commit('setCurrentNoteBlock', '');
    },
    showModal({ commit }, payload) {
      document.body.classList.add('no-scroll'); // won't get at the body any other way

      commit('setModal', payload);
      return new Promise((resolve, reject) => {
        commit('setModalResolve', resolve);
        commit('setModalReject', reject);
      });
    },
    addProjectEntry({ commit, dispatch }, payload) {
      commit('setParentProject', payload);
      dispatch('showModal', 'new-project-entry-wizard');
    },
    editProjectEntry({ commit, dispatch }, payload) {
      commit('setCurrentProjectEntry', payload);
      dispatch('showModal', 'edit-project-entry-wizard');
    },
    addNote({ commit, dispatch }, payload) {
      if (payload.block) {
        commit('setCurrentNoteBlock', payload.block);
        commit('setCurrentContent', payload.content);
      } else {
        commit('setCurrentNoteParent', payload.parent);
      }
      if (payload.type) {
        commit('setNoteType', payload.type);
      }
      dispatch('showModal', 'new-note-wizard');
    },
    editNote({ commit, dispatch }, payload) {
      commit('setCurrentNote', payload);
      dispatch('showModal', 'edit-note-wizard');
    },
    showFullscreenImage({ commit, dispatch }, payload) {
      commit('setImageUrl', payload);
      dispatch('showModal', 'fullscreen-image');
    },
    showFullscreenInfographic({ commit, dispatch }, payload) {
      commit('setInfographic', payload);
      dispatch('showModal', 'fullscreen-infographic');
    },
    showFullscreenVideo({ commit, dispatch }, payload) {
      commit('setVimeoId', payload);
      dispatch('showModal', 'fullscreen-video');
    },
    showMobileNavigation({ commit }, payload) {
      commit('setShowMobileNavigation', payload);
    },
    editModule({ commit }, payload) {
      commit('setEditModule', payload);
    },
    editClassName({ dispatch }) {
      dispatch('showModal', 'edit-class-name-wizard');
    },
    editTeamName({ dispatch }) {
      dispatch('showModal', 'edit-team-name-wizard');
    },
    deactivateUser({ commit, dispatch }, payload) {
      commit('setModulePayload', payload);
      return dispatch('showModal', 'deactivate-person');
    },
  },

  mutations: {
    setModal(state, payload) {
      state.showModal = payload;
    },
    setSpecialContainerClass(state, payload) {
      state.specialContainerClass = payload;
    },
    setScrollPosition(state, payload) {
      state.scrollPosition = payload;
    },
    setContentBlockPosition(state, payload) {
      state.contentBlockPosition = payload;
    },
    setCurrentContent(state, payload) {
      state.currentContent = payload;
    },
    setCurrentNoteBlock(state, payload) {
      state.currentNoteBlock = payload;
    },
    setParentRoom(state, payload) {
      state.parentRoom = payload;
    },
    setCurrentNote(state, payload) {
      state.currentNote = payload;
    },
    setCurrentRoomEntry(state, payload) {
      state.currentRoomEntry = payload;
    },
    setParentModule(state, payload) {
      state.parentModule = payload;
    },
    setParentProject(state, payload) {
      state.parentProject = payload;
    },
    setCurrentProjectEntry(state, payload) {
      state.currentProjectEntry = payload;
    },
    setImageUrl(state, payload) {
      state.imageUrl = payload;
    },
    setInfographic(state, payload) {
      state.infographic = payload;
    },
    setVimeoId(state, payload) {
      state.vimeoId = payload;
    },
    setShowMobileNavigation(state, payload) {
      state.showMobileNavigation = payload;
    },
    setEditModule(state, payload) {
      state.editModule = payload;
    },
    setCurrentNoteParent(state, payload) {
      state.currentNoteParent = payload;
    },
    setNoteType(state, payload) {
      state.noteType = payload;
    },
    setModulePayload(state, payload) {
      state.modulePayload = payload;
    },
    setModalResolve(state, payload) {
      state.modalResolve = payload;
    },
    setModalReject(state, payload) {
      state.modalReject = payload;
    },
  },
});
