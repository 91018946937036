import { defineAsyncComponent } from 'vue';

const registerGlobalComponents = (app: any) => {
  /*********************
   ** Wizards          *
   ********************/
  app.component(
    'NewProjectEntryWizard',
    defineAsyncComponent(() => import('@/components/portfolio/NewProjectEntryWizard.vue'))
  );
  app.component(
    'EditProjectEntryWizard',
    defineAsyncComponent(() => import('@/components/portfolio/EditProjectEntryWizard.vue'))
  );
  app.component(
    'NewNoteWizard',
    defineAsyncComponent(() => import('@/components/notes/NewNoteWizard.vue'))
  );
  app.component(
    'EditNoteWizard',
    defineAsyncComponent(() => import('@/components/notes/EditNoteWizard.vue'))
  );
  app.component(
    'EditClassNameWizard',
    defineAsyncComponent(() => import('@/components/school-class/EditClassNameWizard.vue'))
  );
  app.component(
    'EditTeamNameWizard',
    defineAsyncComponent(() => import('@/components/profile/EditTeamNameWizard.vue'))
  );
  app.component(
    'EditSnapshotTitleWizard',
    defineAsyncComponent(() => import('@/components/snapshots/EditSnapshotTitleWizard.vue'))
  );

  /*********************
   ** Layouts          *
   ********************/
  app.component(
    'DefaultLayout',
    defineAsyncComponent(() => import('@/layouts/DefaultLayout.vue'))
  );
  app.component(
    'SimpleLayout',
    defineAsyncComponent(() => import('@/layouts/SimpleLayout.vue'))
  );
  app.component(
    'FullScreenLayout',
    defineAsyncComponent(() => import('@/layouts/FullScreenLayout.vue'))
  );
  app.component(
    'PublicLayout',
    defineAsyncComponent(() => import('@/layouts/PublicLayout.vue'))
  );
  app.component(
    'BlankLayout',
    defineAsyncComponent(() => import('@/layouts/BlankLayout.vue'))
  );
  app.component(
    'SplitLayout',
    defineAsyncComponent(() => import('@/layouts/SplitLayout.vue'))
  );

  /*********************
   ** Modals           *
   ********************/
  app.component(
    'Modal',
    defineAsyncComponent(() => import('@/components/Modal.vue'))
  );
  app.component(
    'FullscreenImage',
    defineAsyncComponent(() => import('@/components/FullscreenImage.vue'))
  );
  app.component(
    'FullscreenInfographic',
    defineAsyncComponent(() => import('@/components/FullscreenInfographic.vue'))
  );
  app.component(
    'FullscreenVideo',
    defineAsyncComponent(() => import('@/components/FullscreenVideo.vue'))
  );
  app.component(
    'DeactivatePerson',
    defineAsyncComponent(() => import('@/components/profile/DeactivatePerson.vue'))
  );
  app.component(
    'SnapshotCreated',
    defineAsyncComponent(() => import('@/components/modules/SnapshotCreated.vue'))
  );
  app.component(
    'ChangeVisibility',
    defineAsyncComponent(() => import('@/components/rooms/ChangeVisibility.vue'))
  );
  app.component(
    'Confirm',
    defineAsyncComponent(() => import('@/components/modals/LegacyConfirm.vue'))
  );
};

export default registerGlobalComponents;
