import 'core-js/stable';
import setupApp from './setup/setupApp';
import registerPlugins from './setup/plugins';
import setupRouteGuards from './setup/router';
import registerDirectives from './setup/directives';
import registerGlobalComponents from './setup/components';
import '@/styles/css/main.css';
import '@/styles/main.scss';

import '@/styles/css/themes/variables.css';

const app = setupApp();

registerPlugins(app);
setupRouteGuards();

registerDirectives(app);

registerGlobalComponents(app);

app.mount('#app');
