<template>
  <transition name="fade">
    <a
      class="scroll-up"
      v-if="scroll > 200"
      @click="scrollTop"
    >
      <arrow-up class="scroll-up__icon" />
    </a>
  </transition>
</template>

<script lang="ts">
import { defineAsyncComponent } from 'vue';
const ArrowUp = defineAsyncComponent(() => import('@/components/icons/ArrowUp.vue'));

export default {
  components: {
    ArrowUp,
  },

  data() {
    return {
      scroll: 0,
    };
  },

  mounted() {
    let html = document.scrollingElement;
    document.body.onscroll = () => {
      this.scroll = html.scrollTop;
    };
  },

  unmounted() {
    document.body.onscroll = null;
  },

  methods: {
    scrollTop() {
      document.scrollingElement.scrollTop = 0;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_mixins.scss';

.scroll-up {
  position: fixed;
  right: $large-spacing;
  bottom: $large-spacing;
  padding: $medium-spacing;
  border-radius: 100px;
  @include default-box-shadow;
  cursor: pointer;
  background-color: $color-white;
  border: 1px solid $color-silver;
  z-index: 2;

  &__icon {
    width: 50px;
    height: 50px;
    fill: $color-brand;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
